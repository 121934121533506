import { render, staticRenderFns } from "./mkp-singup-validation.vue?vue&type=template&id=08b1741f&scoped=true&"
import script from "./mkp-singup-validation.vue?vue&type=script&lang=js&"
export * from "./mkp-singup-validation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b1741f",
  null
  
)

export default component.exports