<template>
  <div>
    <span>icon</span>
    <h3>{{ $t("forms.singup.validation.title") }}</h3>

    <mkp-text-field>
      {{
        $t("forms.singup.validation.text.first") +
          props.email +
          $t("forms.singup.validation.text.second")
      }}
    </mkp-text-field>

    <mkp-button @click="closeModal">
      {{ $t("forms.singup.validation.buttons.end") }}
    </mkp-button>
  </div>
</template>

<script>
import MkpTextField from "../../../../simple/text-field/mkp-text-field";
import MkpButton from "../../../../simple/button/mkp-button";
export default {
  name: "MkpSingupValidation",
  components: { MkpButton, MkpTextField },
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch("setModalState");
    }
  }
};
</script>

<style scoped></style>
